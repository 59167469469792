import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from "../HomePage/HomePage";
import React from "react";
import RegisterPage from "../RegisterPage/RegisterPage";
import AddFunds from "../Funds/AddFund";
import SignOut from "../../components/signOut/SignOut";



const DashboardRoutes = ()=>{
    return(
        <Switch>
            <Route path={'/payment_methods'}>
                <RegisterPage/>
            </Route>
            <Route path={'/funds'}>
                <AddFunds/>
            </Route>
            <Route path={'/logout'}>
                <SignOut/>
            </Route>
            <Route path={'/'}>
                <HomePage/>
            </Route>
        </Switch>
    )

}
export default DashboardRoutes