import RootNavigator from './RootNavigation';
import {Provider} from 'react-redux';
import {store,persistor } from './store/store';
import Error from "./pages/error/Error";
import React from "react";
import { PersistGate } from 'redux-persist/integration/react'


const App = () => {

  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Error/>
      <RootNavigator/>
      </PersistGate>
      </Provider>

  );
};

export default App;
