import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { LoginPage, RegisterPage, DashboardPage } from './pages';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {setLoggedInSuccess,signOut} from "./store/user";

const axios = require('axios').default;
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API;
axios.defaults.withCredentials = true

const RootNavigator = ({loggedInUser,setLoggedInSuccess,signOut}) => {
    useEffect(()=>{
        axios.get('/auth/status').then(res=>{
            if(res.data.isLoggedIn === true)
                setLoggedInSuccess(res.data)
            else{
                signOut()}
        })
    },[])
const authenticatedRoutes = ()=>{
    return(
      <Switch>
        <Route path="/">
          <DashboardPage />
        </Route>
      </Switch>
   )
    
}
const unauthenticatedRoutes = ()=>{
    return(
      <Switch>
       <Route path="/login">
              <LoginPage />
          </Route>
          <Route path="/register">
              <RegisterPage />
          </Route>
      <Route path="/">
              <LoginPage />
        </Route>

      </Switch>
    )
    
}
const getRoutes = ()=>{
  return loggedInUser&&loggedInUser.name ? authenticatedRoutes() : unauthenticatedRoutes()

}
return(
  <Router>
    {getRoutes()}
    </Router>
)

}
export default connect(({auth}) => {
    return {
        loggedInUser: auth.loggedInUser,
    };
}, {setLoggedInSuccess,signOut})(RootNavigator);