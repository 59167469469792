/** @jsxImportSource @emotion/react */

import axios from 'axios';
import {useEffect, useState} from 'react';
// import { FiSearch } from 'react-icons/fi';

import { formStyles, inputSearchStyles } from './styles';
import { SetMessage } from '../../utils/error';
import {filterFiles, setFilesResult} from "../../store/files";
import {connect} from "react-redux";
import {changeCredit, setCredit} from "../../store/user";
const InputSearch = ({setFilesResult,filterFiles,changeCredit,setCredit}) => {
  const [inputSearch, setInputSearch] = useState('');
    useEffect(()=>{
        filterFiles(inputSearch)
    },[inputSearch])
    const getFiles = ()=>{
        axios.get('/files').then(res=>{
            setFilesResult(res.data.files)
            setCredit(res.data.credit)
        })
    }
    const handleFileUplaod = (e)=>{

       const fileType = e.target.files[0].name.split('.').pop().toLowerCase()
       const csv =/csv/
       const xlsx = /xlsx/
       const xls = /xls/
       const odt = /odt/
      if(!csv.test(fileType) && !xlsx.test(fileType) && !xls.test(fileType)  && !odt.test(fileType)){
        SetMessage("Wrong file type  we are only supporting csv and xlsx (Excel) file formats ",'error')
        return
      }

      const formData = new FormData()
      formData.append('file',e.target.files[0])
      const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
        axios.post('/file', formData,config).then((res)=>{
           SetMessage("File uplaoded Successfuly",'success')
             getFiles()
             setCredit(res.data.credit)
      }).catch(e =>{
        SetMessage("File upload Failed check if you have enough credits",'error')})

    }
  return (
    <form css={formStyles}>
      <div class="fileUpload">
        <input type="file" class="upload" onChange={handleFileUplaod} />
        <span>Add Document</span>
      </div>
      <input
        css={inputSearchStyles}
        type="text"
        placeholder="Search..."
        value={inputSearch}
        onChange={(e) => setInputSearch(e.target.value)}
      />
    </form>
  );
};
const mapStateToProps = ({}) => {
    return {

    };
};
export default connect(mapStateToProps, {
    setFilesResult,filterFiles,changeCredit,setCredit
})(InputSearch);
