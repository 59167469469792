import {createSlice} from "@reduxjs/toolkit";
import { cloneDeep,clone } from 'lodash';

const file = createSlice({
    name:'file',
    initialState:{
        files: [],
        tempFiles:[],
        errorMessage:false
    },
    reducers: {
        setFilesResult(state, action){
            state.files = action.payload
        },
        addFile(state,action){
            state.files.push(action.payload)
        },
        filterFiles(state,action){


            if(action.payload !== '' && action.payload !== ' ' ) {
                if(state.tempFiles.length === 0)
                state.tempFiles = state.files
                state.files = state.files.filter((fileItem) => fileItem.name.toLowerCase().includes(action.payload.toLowerCase()))
            }
            else {
                if(state.tempFiles.length > 0 )
                state.files = state.tempFiles

                state.tempFiles = []
            }
        }
    }
})
const {actions,reducer}= file

export const {setFilesResult,addFile,filterFiles} =actions
export default reducer;